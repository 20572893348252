import { css } from '@emotion/react'

export const sitemap = css`
  .sitemap-btn {
    li {
      border-top: none;
    }
    a {
      font-size: 14px;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  .sitemap-link {
    a {
      display: block;
      font-size: 14px;
      font-weight: bold;
      padding: 16px;
    }
  }
  ul {
    list-style: none;
    padding-left: 16px;
    margin-bottom: 8px;
    li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 24px;
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        content: '';
        display: block;
        height: 1px;
        width: 6px;
        background-color: #cc050b;
      }
      a {
        font-size: 14px;
      }
    }
  }
  .gatsby-image-wrapper {
    position: relative;
    top: 2px;
    margin-left: 6px;
  }
  .gatsby-image-wrapper img {
    width: 12px;
    height: auto;
  }

  @media screen and (min-width: 1199px) {
    .sitemap-col {
      width: calc(33.3333% - 21.3333px);
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
