import React from 'react'
import { ListSitemap } from '../components/molecules/List/ListSitemap'
import { LayoutNoSideNav } from '../components/organisms/Layout/LayoutNoSideNav'
import { LinkBase } from '../components/atoms/Link/LinkBase'
import { sitemap } from '../components/styles/sitemap'
import { SEO } from '../components/data/SEO'
import { navFooter } from '../components/data/nav'
import { ENGLISH_TITLE } from '../components/data/English'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="サイトマップ"
      headingEnglish={ENGLISH_TITLE.Sitemap}
      BreadcrumbData={[{ title: 'サイトマップ', url: '/' }]}
    >
      <div>
        <div className="pc:pt-12 pc:px-8 pb-12 bg-white pc:flex" css={sitemap}>
          <div className="sitemap-col">
            <div className="sitemap-btn border-t border-border10">
              <ListSitemap text="Home" href="/" />
            </div>
            <div className="sitemap-btn mb-2">
              <ListSitemap text="企業情報" href="/company" />
            </div>
            {navFooter
              .filter((row) => row.text === '企業情報')[0]
              .children.map((item) => (
                <div className="sitemap-link">
                  <LinkBase className="text-md" href={item.url}>
                    {item.text}
                  </LinkBase>
                </div>
              ))}
            <div className="sitemap-btn mb-2 border-t border-border10">
              <ListSitemap text="事業案内" href="/service" />
            </div>
            <div className="sitemap-link">
              {navFooter
                .filter((row) => row.text === '事業案内')[0]
                .children.map((item) => (
                  <div className="sitemap-link">
                    <LinkBase className="text-md" href={item.url}>
                      {item.text}
                    </LinkBase>
                  </div>
                ))}
            </div>
            <div className="sitemap-btn border-t border-border10">
              <ListSitemap text="サステナビリティ" href="/sustainability" />
            </div>
          </div>

          <div className="sitemap-col">
            <div className="sitemap-btn mb-2 pc:border-t pc:border-border10">
              <ListSitemap text="株主・投資家の皆さまへ" href="/ir" />
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/individual'}>
                個人投資家の皆さまへ
              </LinkBase>
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/news'}>
                IRニュース
              </LinkBase>
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/policy'}>
                経営方針
              </LinkBase>
            </div>
            <ul>
              <li>
                <LinkBase className="text-md" href={'/ir/policy/management'}>
                  代表メッセージ
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/policy/philosophy'}>
                  企業理念・行動指針
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/policy/strength'}>
                  CRGグループの強み
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/policy/disclosure'}>
                  ディスクロージャーポリシー
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/policy/governance'}>
                  コーポレートガバナンス
                </LinkBase>
              </li>
            </ul>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/performance'}>
                業績・財務ハイライト
              </LinkBase>
            </div>
            <ul>
              <li>
                <LinkBase
                  className="text-md"
                  href={'/ir/performance/highlight'}
                >
                  業績ハイライト
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/performance/finance'}>
                  財政状態
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/performance/cashflow'}>
                  キャッシュフローの状況
                </LinkBase>
              </li>
            </ul>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/library'}>
                IRライブラリ
              </LinkBase>
            </div>
            <ul>
              <li>
                <LinkBase className="text-md" href={'/ir/library/results'}>
                  決算短信
                </LinkBase>
              </li>
              <li>
                <LinkBase
                  className="text-md"
                  href={'/ir/library/presentations'}
                >
                  決算説明資料
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/library/securities'}>
                  有価証券報告書等
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/library/reports'}>
                  株主通信
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/library/disclosure'}>
                  適時開示情報
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/library/others'}>
                  その他IR資料
                </LinkBase>
              </li>
            </ul>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/stock'}>
                株式・株主情報
              </LinkBase>
            </div>
            <ul>
              <li>
                <LinkBase className="text-md" href={'/ir/stock/information'}>
                  株式基本情報
                </LinkBase>
              </li>
              <li className="flex items-center space-x-1">
                <LinkBase
                  className="text-md"
                  href={'https://finance.yahoo.co.jp/quote/7041.T'}
                >
                  株価情報
                </LinkBase>
                <img src="/common/img/ex.svg" alt="" />
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/stock/shareholders'}>
                  株主総会
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/stock/regulation'}>
                  定款・株式取扱規程
                </LinkBase>
              </li>
              <li>
                <LinkBase className="text-md" href={'/ir/stock/procedure'}>
                  株式事務手続き
                </LinkBase>
              </li>
            </ul>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/schedule'}>
                IRスケジュール
              </LinkBase>
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/notice'}>
                電子公告
              </LinkBase>
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/disclaimer'}>
                免責事項
              </LinkBase>
            </div>
            <div className="sitemap-link">
              <LinkBase className="text-md" href={'/ir/faq'}>
                よくあるご質問
              </LinkBase>
            </div>
          </div>

          <div className="sitemap-col">
            <div className="sitemap-btn border-t border-border10">
              <ListSitemap text="ニュース" href="/news" />
            </div>
            <div className="sitemap-btn">
              <ListSitemap text="お問い合わせ" href="/contact" />
            </div>
            <div className="sitemap-btn">
              <ListSitemap
                text="採用情報"
                href="https://en-gage.net/cr2_saiyo1/"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="サイトマップ" url="/sitemap" />
